import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from "@tanstack/react-query";
import { useSession } from "@/entities/Session/useSession/useSession";
import { useCallback, useEffect, useState } from "react";
import { omit } from "lodash";
import { useRouter } from "@/utils";
import { toast } from "@/ui";
import { invalidateQueries } from "./invalidateQueries";
import { useApiClient } from "../useApiClient/useApiClient";
import type {
    MutationConfig,
    MutationConfigOptions,
} from "@/api/types/mutation.types";
import type { AppContext } from "@/api/types/context.types";

type UseApiMutationOptions<TReturn, TPayload = TReturn, TParams = any> = {
    params?: TParams; // params to pass to the mutationFn
    toastsEnabled?: boolean;
} & Partial<UseMutationOptions<TReturn, unknown, Partial<TPayload>, unknown>> &
    Partial<MutationConfigOptions>;

// Custom hook to wrap react query and inject api and session
export function useApiMutation<TReturn, TPayload = TReturn, TParams = any>(
    mutation: MutationConfig<TReturn, TPayload>,
    hookOptions: UseApiMutationOptions<TReturn, TPayload, TParams> = {},
) {
    const options = {
        toastsEnabled: true,
        ...omit(mutation, "mutationFn"),
        ...hookOptions,
    } as UseApiMutationOptions<TReturn, TPayload, TParams>;
    const [mutationQueue, setMutationQueue] = useState<Partial<TPayload>[]>([]);
    const queryClient = useQueryClient();
    const session = useSession({ enabled: options.waitForSession });
    const router = useRouter();
    const api = useApiClient();
    const appContext = {
        api,
        session: session,
        params: options.params,
        queryClient,
        router,
    } as AppContext;
    const mutationFn = (variables) =>
        mutation.mutationFn({ ...appContext, payload: variables });

    const result = useMutation({
        mutationFn,
        ...options,
        onError: (...args) => {
            const { errorToast, toastsEnabled } = options;
            if (toastsEnabled && errorToast)
                toast.error(errorToast.message, errorToast.options);

            if (options.onError) options.onError(...args);
        },
        onSuccess: (...args) => {
            const invalidationList = options.invalidates || [];
            invalidateQueries(invalidationList, appContext);

            const { successToast, toastsEnabled } = options;
            if (toastsEnabled && successToast)
                toast.success(successToast.message, successToast.options);
            if (options.onSuccess) options.onSuccess(...args);
        },
        onSettled: options.onSettled,
    });

    useEffect(() => {
        if (
            mutationQueue.length > 0 &&
            (!options.waitForSession || session.user)
        ) {
            result.mutate(mutationQueue[0]);
            setMutationQueue(mutationQueue.slice(1));
        }
    }, [session]);

    return {
        ...result,
        session,
        params: options.params,
        router,
        queryClient,
        mutateAsync: useCallback(
            async (variables: Partial<TPayload> = {}) => {
                if (!options.waitForSession || session.user) {
                    return await result.mutateAsync(variables);
                } else {
                    setMutationQueue([...mutationQueue, variables]);
                }
            },
            [mutationQueue, session],
        ),
        mutate: useCallback(
            (variables: Partial<TPayload> = {}) => {
                if (!options.waitForSession || session.user) {
                    result.mutate(variables);
                } else {
                    setMutationQueue([...mutationQueue, variables]);
                }
            },
            [mutationQueue, session],
        ),
    };
}
