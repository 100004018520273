import type { Session } from "../Session.model";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { User } from "@/entities/User";
import { getIdentitySessionQuery } from "../Session.queries";
import { useToken } from "./useToken";

export const useSession = ({
    enabled = true,
}: {
    enabled?: boolean;
} = {}): Session => {
    const auth0Session = useAuth0();

    const token = useToken();

    const identity = useQuery<User | null>({
        ...getIdentitySessionQuery(token.data),
        enabled: enabled && !!token.data,
    });

    const tokenLoading = !token.data && token.isLoading;
    const identityLoading = !identity.data && identity.isLoading;

    const auth0Error = !auth0Session.user && !!auth0Session.error;
    const tokenError = !token.data && token.isError;

    return {
        isLoading:
            (!auth0Session.isAuthenticated && auth0Session.isLoading) ||
            tokenLoading ||
            identityLoading,
        isAuthenticated: !!identity.data,
        isAuth0Error: auth0Error || tokenError,
        isHumError: !identity.data && identity.isError,
        auth0: auth0Session?.user || null,
        token,
        user: identity.data,
    };
};
