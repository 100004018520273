import { useParentSize } from "@visx/responsive";
import { clsx } from "clsx";

export type LegendConfig = {
    className: string;
    label: string;
};

export function ChartContainer({
    title,
    children,
    legends,
    isCondensed = true,
}: {
    title: string;
    description: string;
    children: React.FC<{
        width: number;
        height: number;
    }>;
    legends?: LegendConfig[];
    isCondensed?: boolean;
}) {
    const {
        parentRef: containerRef,
        width: containerWidth,
        height: containerHeight,
    } = useParentSize({ debounceTime: 15 });
    const { parentRef: headerRef, height: headerHeight } = useParentSize({
        ignoreDimensions: ["width"],
    });
    const containerPadding = 32;
    const width = containerWidth - 16;
    const height = containerHeight - headerHeight - containerPadding;

    return (
        <div
            className={clsx(
                "flex flex-col",
                isCondensed ? "h-[480px]" : "h-[560px]",
            )}
        >
            <div
                className={clsx(
                    "bg-secondary p-6",
                    "flex h-full flex-col text-clip rounded",
                )}
                ref={containerRef}
            >
                <div className="header mb-4" ref={headerRef}>
                    <div className={clsx("", "items-start justify-between")}>
                        <div className="mb-4">
                            <h1 className="heading-m mb-4">{title}</h1>
                        </div>
                        <span />

                        <div className={clsx("w-full pr-4", "flex  gap-4")}>
                            {legends?.map(({ className, label }) => (
                                <div
                                    key={label + "legend"}
                                    className="flex items-center gap-2"
                                >
                                    <div className={className} />
                                    <p className="body-micro subdued ">
                                        {label}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex shrink grow items-center">
                    {children({ width, height })}
                </div>
            </div>
        </div>
    );
}
