import { Icon } from "@/ui";
import clsx from "clsx";

export const Step = ({
    active = false,
    completed = false,
    text,
}: {
    active?: boolean;
    completed?: boolean;
    text: string;
}) => {
    return (
        <div className="flex w-full gap-8 py-7">
            {active && <Icon.MapPin />}
            {completed && <Icon.Check />}
            {!active && !completed && <Icon.StepInProgress />}
            <h2
                className={clsx("heading-m", {
                    subdued: !active,
                })}
            >
                {text}
            </h2>
        </div>
    );
};
