import clsx from "clsx";
import { motion } from "framer-motion";
import { ReactNode } from "react";

export function Widget({
    children,
    accented = false,
    header,
}: {
    header?: string;
    accented?: boolean;
    children: ReactNode;
}) {
    return (
        <motion.div
            layout
            className={clsx(
                "bg-secondary h-full p-6",
                "hover:border-default border border-transparent",
                "rounded",
                accented && "bg-slate-700",
            )}
        >
            {header && <h2 className="heading-l mb-5">{header}</h2>}
            {children}
        </motion.div>
    );
}
